import React, { useEffect } from 'react'
import './App.css'
import './assets/css/tailwind.css'
import { Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react'
import { AppMobileMenu, AppSidebar } from './appComponents/layout/app-sidebar/AppSidebar'
import AppTopBar from './appComponents/layout/app-topbar/AppTopbar'
import DashboardPage from './pages/DashboardPage'
import AccountSetupPage from './pages/setup/AccountSetupPage'
import DiscoverySetupPage from './pages/setup/DiscoverySetupPage'
import TransactionsPage from './pages/TransactionsPage'
import LoyaltySettingsPage from './pages/settings/LoyaltySettingsPage'
import DiscoverySettingsPage from './pages/settings/DiscoverySettingsPage'
import MerchantIdSettingsPage from './pages/settings/MerchantIdSettingsPage'
import { useAppSelector } from './redux/Hooks'
import { GetAccountDetailsAction } from './redux/reducers/UserAccount/UserAccountActionCreators'
import Authenticated from './auth/Authenticated'
import EcommerceStore from './pages/Ecommerce/EcommerceStore'
import LoginB2CDev from './auth/LoginB2CDev'
import EventsPage from './pages/Events/EventsPage'

function App() {
  const { loggedIn } = useAppSelector(({ authReducer: reducer }) => reducer)
  const dispatch = useDispatch()

  useEffect(() => {
    if (loggedIn) {
      dispatch(GetAccountDetailsAction())
    }
  }, [])

  const isAuthenticated = useIsAuthenticated()
  // eslint-disable-next-line no-console
  console.log('isAuthenticated', isAuthenticated)

  return (
    <div>
      <AuthenticatedTemplate>
        <Main />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Switch>
          <Route path="/login" component={LoginB2CDev} />
          <Route path="/authenticated" exact component={Authenticated} />
        </Switch>
        <div className="bg-pageBackground">
          <LoginB2CDev />
        </div>
      </UnauthenticatedTemplate>
    </div>
  )
}

export default App

const Main = () => {
  const { isSideMenuOpen, isMobileMenuOpen } = useAppSelector(({ appReducer: reducer }) => reducer)

  return (
    <div>
      <AppTopBar />
      <div className="min-h-screen">
        <div>
          <div className="flex">
            {isSideMenuOpen && <AppSidebar />}
            {isMobileMenuOpen && <AppMobileMenu />}
            <div className={['w-full mt-11 overflow-hidden bg-pageBackground', isSideMenuOpen ? 'sm:ml-72' : 'ml-0'].join(' ')}>
              <Switch>
                <Route path="/" exact component={DashboardPage} />
                <Route path="/setup/account-setup" exact component={AccountSetupPage} />
                <Route path="/setup/business-profile-setup" exact component={DiscoverySetupPage} />
                <Route path="/transactions" exact component={TransactionsPage} />
                <Route path="/settings/loyalty" exact component={LoyaltySettingsPage} />
                <Route path="/settings/business-profile" exact component={DiscoverySettingsPage} />
                <Route path="/settings/merchant-id" exact component={MerchantIdSettingsPage} />
                <Route path="/ecommerce" exact component={EcommerceStore} />
                <Route path="/events" exact component={EventsPage} />
                <Route path="/login" component={LoginB2CDev} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const MainOld = () => {
  const { isSideMenuOpen, isMobileMenuOpen } = useAppSelector(({ appReducer: reducer }) => reducer)

  return (
    <div>
      <AppTopBar />
      <div className="min-h-screen">
        <div>
          <div className="flex">
            {isSideMenuOpen && <AppSidebar />}
            {isMobileMenuOpen && <AppMobileMenu />}
            <div className={['w-full mt-14 py-6 sm:py-8 overflow-hidden bg-pageBackground', isSideMenuOpen ? 'sm:ml-72' : 'ml-0'].join(' ')}>
              <Switch>
                <Route path="/" exact component={DashboardPage} />
                <Route path="/setup/account-setup" exact component={AccountSetupPage} />
                <Route path="/setup/business-profile-setup" exact component={DiscoverySetupPage} />
                <Route path="/transactions" exact component={TransactionsPage} />
                <Route path="/settings/loyalty" exact component={LoyaltySettingsPage} />
                <Route path="/settings/business-profile" exact component={DiscoverySettingsPage} />
                <Route path="/settings/merchant-id" exact component={MerchantIdSettingsPage} />
                <Route path="/module/ecommerce" exact component={EcommerceStore} />
                <Route path="/login" component={LoginB2CDev} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
