import React, { Dispatch, useEffect } from 'react'
import { useImmerReducer } from 'use-immer'
import { useDispatch } from 'react-redux'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DashboardActions, DashboardInitialState, DashboardReducer, DashboardState } from './context/DashboardReducer'
import DashboardSignupProgress from './components/DashboardSignupProgress'
import DashboardPendingApproval from './components/DashboardPendingApproval'
import DashboardBusinessOverview from './components/DashboardBusinessOverview'
import { useAppSelector } from '../../redux/Hooks'
import DashboardNewBusiness from './components/DashboardNewBusiness'
import {
  businessApprovedWithoutTransactions,
  businessApprovedWithTransactions,
  businessPending,
  businessPendingApproval,
} from '../../helpers/BusinessStatusHelper'

export const DashboardStateContext = React.createContext<DashboardState>({} as DashboardState)
export const DashboardDispatchContext = React.createContext<Dispatch<DashboardActions>>({} as Dispatch<DashboardActions>)

const DashboardPage = () => {
  const [state, dispatch] = useImmerReducer(DashboardReducer, DashboardInitialState)
  const appDispatch = useDispatch()

  useEffect(() => {
    // appDispatch(GetDiscoveryStatusAction())
    // appDispatch(GetSignupStatusAction())
    appDispatch({ type: 'selectSideMenuItem', sideMenuItemType: 'dashboard' })
  }, [])

  return (
    <DashboardDispatchContext.Provider value={dispatch}>
      <DashboardStateContext.Provider value={state}>
        <div className="px-2 md:max-w-5xl mx-auto">
          <DashboardPageSelector />
        </div>
      </DashboardStateContext.Provider>
    </DashboardDispatchContext.Provider>
  )
}

export default DashboardPage

const DashboardPageSelector = () => {
  const { userAccount } = useAppSelector(({ userAccountReducer: reducer }) => reducer)
  const { completedSignup } = useAppSelector(({ setupAccountReducer: reducer }) => reducer)
  const { completedDiscovery } = useAppSelector(({ setupDiscoveryReducer: reducer }) => reducer)

  if (businessApprovedWithTransactions(userAccount)) {
    return <DashboardBusinessOverview />
  }

  if (businessApprovedWithoutTransactions(userAccount)) {
    return <DashboardNewBusiness />
  }

  if (businessPendingApproval(userAccount) || (completedSignup && completedDiscovery)) {
    return <DashboardPendingApproval />
  }

  if (businessPending(userAccount)) {
    return <DashboardSignupProgress />
  }

  return <FontAwesomeIcon icon={faSpinner} size="lg" className="text-gray-400 my-2" spin />
}
