import { faCog, faHome, faList } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const navigationNonApproved: SideBarMenuProps[] = [
  { name: 'Dashboard', menuItemType: 'dashboard', icon: faHome, current: false, href: '/' },
  {
    name: 'Setup',
    menuItemType: 'setup',
    icon: faCog,
    current: false,
    children: [
      { name: 'Account set-up', menuItemType: 'setupAccount', href: '/setup/account-setup', current: false },
      {
        name: 'Business set-up',
        menuItemType: 'setupDiscovery',
        href: '/setup/business-profile-setup',
        current: false,
      },
    ],
  },
]

export const navigationApproved: SideBarMenuProps[] = [
  { name: 'Dashboard', menuItemType: 'dashboard', icon: faHome, current: false, href: '/' },
  { name: 'Transactions', menuItemType: 'transactions', icon: faList, current: false, href: '/transactions' },
  {
    name: 'Settings',
    menuItemType: 'settings',
    icon: faCog,
    current: false,
    children: [
      { name: 'Loyalty', menuItemType: 'settingsLoyalty', href: '/settings/loyalty', current: false },
      {
        name: 'Business Profile',
        menuItemType: 'settingsBusinessprofile',
        href: '/settings/business-profile',
        current: false,
      },
      { name: 'Merchant ID', menuItemType: 'settingsMerchantId', href: '/settings/merchant-id', current: false },
      { name: 'E-commerce', menuItemType: 'ecommerceStore', href: '/ecommerce', current: false, autoCloseMenu: true },
      { name: 'Events', menuItemType: 'events', href: '/events', current: false, autoCloseMenu: true },
    ],
  },
]

export type SideBarMenuProps = {
  name: string
  menuItemType: SideMenuItemType
  icon?: IconProp
  current?: boolean
  href?: string
  children?: SideBarMenuProps[]
  autoCloseMenu?: boolean
}

export type SideMenuItemType =
  | 'dashboard'
  | 'setupAccount'
  | 'setup'
  | 'setupDiscovery'
  | 'transactions'
  | 'settings'
  | 'settingsLoyalty'
  | 'settingsBusinessprofile'
  | 'settingsMerchantId'
  | 'ecommerceStore'
  | 'events'
