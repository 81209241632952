import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import React from 'react'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from './authConfig'
import ErrorComponent from './ErrorComponent'

const LoginB2CDev = () => {
  const msalInstance = useMsal()

  const getInfo = () => {
    const accounts = msalInstance.instance.getAllAccounts()
    const activeAccount = msalInstance.instance.getActiveAccount()

    const request = {
      scopes: process.env.REACT_APP_ADB2C_LOGIN_SCOPES?.split(',') ?? [],
      account: activeAccount || accounts[0],
    }

    console.log('acquireTokenSilent Accounts', accounts)
    console.log('acquireTokenSilent ActiveAccount', activeAccount)

    msalInstance.instance
      .acquireTokenSilent(request)
      .then((response) => {
        console.log('acquireTokenSilent', response)
        console.log('acquireTokenSilent token', response.accessToken)
      })
      .catch((error) => {
        console.log('')
      })
  }

  const loading = () => (
    <>
      <div>Loading....</div>
    </>
  )

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={ErrorComponent}
      loadingComponent={loading}>
      <div className="flex flex-col space-y-6 ">
        <div className="font-bold">Dev</div>
        <div className="font-bold">Logged In</div>
        {/* <SuccessButton label="Info" size="medium" onClick={() => getInfo()} /> */}
        {/* <SignInButton /> */}
        {/* <SignOutButton /> */}
      </div>
    </MsalAuthenticationTemplate>
  )
}

export default LoginB2CDev
