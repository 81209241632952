import React, { useEffect, useState } from 'react'
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Label2XLarge, Label4XLarge, LabelMedium } from '@pixieme/pixie-react-components'
import { useDispatch } from 'react-redux'
import { AccountDetailsLoyaltyPercentForm } from '../setup/AccountSetupPage/components/AccountDetailsLoyaltyPercent'
import { GetLoyaltyPercentResponse } from '../../services/pixie/Models/ApiModels'
import { setupAccountService } from '../../services/pixie'
import { useAppSelector } from '../../redux/Hooks'

const LoyaltySettingsPage = () => {
  const [loyaltyPercent, setLoyaltyPercent] = useState<number | undefined>()
  const [loading, setLoading] = useState<boolean>(true)
  const {
    status: { savedLoyaltyPercentage },
  } = useAppSelector(({ setupAccountReducer: reducer }) => reducer)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'resetLoyaltyPercentage' })
    setupAccountService
      .getLoyaltyPercentage()
      .then((response: GetLoyaltyPercentResponse) => {
        setLoyaltyPercent(response.loyaltyPercent)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <div className="md:max-w-5xl mx-auto px-2">
      <div className="bg-white shadow-sm rounded-md p-6 sm:p-8">
        <Label4XLarge variant="dark" label="Loyalty Percentage" additionalCss="font-extrabold tracking-tight hidden sm:flex" />
        <Label2XLarge variant="dark" label="Loyalty Percentage" additionalCss="font-extrabold tracking-tight sm:hidden" />
        <div className="pt-8">
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} size="lg" className="text-gray-400 my-2" spin />
          ) : savedLoyaltyPercentage ? (
            <LoyaltySaved />
          ) : (
            <AccountDetailsLoyaltyPercentForm previousLoyaltyPercent={loyaltyPercent} />
          )}
        </div>
      </div>
    </div>
  )
}

export default LoyaltySettingsPage

const LoyaltySaved = () => (
  <div>
    <div className="sm:flex py-2 bg-white rounded-md flex space-x-4 items-center">
      <FontAwesomeIcon icon={faCheckCircle} size="2x" className="text-success" />
      <LabelMedium variant="dark" label="Your loyalty percentage has been successfully saved" />
    </div>
  </div>
)
