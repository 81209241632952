import { Dispatch } from 'react'
import { pixieService, setupDiscoveryService } from '../../../services/pixie'
import { DiscoveryBasicsInformation, DiscoveryClassification } from '../../../models/models'
import {
  AddDiscoveryBasicsResponse,
  BusinessCategory,
  BusinessCategoryResponse,
  DiscoveryCopyData,
  DiscoveryPhotosData,
  DiscoveryStatusResponse,
  FormValidationApError,
  TelephoneValidationError,
} from '../../../services/pixie/Models/ApiModels'
import { IsApiFormValidationError, IsApiTelephoneFormValidationError } from '../../../helpers/ApiHelpers'

export type SetupDiscoveryActions =
  | { type: 'getDiscoveryStatusRequest' }
  | { type: 'getDiscoveryStatusSuccess'; discoveryStatus: DiscoveryStatusResponse }
  | { type: 'getDiscoveryStatusFailure'; error: string; description: string }
  | { type: 'resetBasics' }
  | { type: 'addBasicsRequest'; basics: DiscoveryBasicsInformation }
  | { type: 'addBasicsSuccess' }
  | { type: 'addBasicsFailure'; error: string; description: string }
  | { type: 'addBasicsFormValidationErrors'; error: FormValidationApError }
  | { type: 'getBusinessCategoriesRequest' }
  | { type: 'getBusinessCategoriesSuccess'; categories: BusinessCategory[] }
  | { type: 'getBusinessCategoriesFailure'; error: string; description: string }
  | { type: 'addClassificationRequest'; classification: DiscoveryClassification }
  | { type: 'addClassificationSuccess' }
  | { type: 'addClassificationFailure'; error: string; description: string }
  | { type: 'addPhotosRequest'; photosData: DiscoveryPhotosData }
  | { type: 'addPhotosSuccess' }
  | { type: 'addPhotosFailure'; error: string; description: string }
  | { type: 'addCopyRequest'; copyData: DiscoveryCopyData }
  | { type: 'addCopySuccess' }
  | { type: 'addCopyFailure'; error: string; description: string }
  | { type: 'addBioPhotoRequest'; bioPhoto: any }
  | { type: 'addBioPhotoSuccess' }
  | { type: 'addBioPhotoFailure'; error: string; description: string }
  | { type: 'resetStoreSetupDiscovery' }

const GetDiscoveryStatusAction =
  () =>
  async (dispatch: Dispatch<SetupDiscoveryActions>): Promise<DiscoveryStatusResponse | null> => {
    try {
      dispatch({ type: 'getDiscoveryStatusRequest' })
      const discoveryStatus: DiscoveryStatusResponse = await setupDiscoveryService.getDiscoveryStatus()
      dispatch({ type: 'getDiscoveryStatusSuccess', discoveryStatus })
      return discoveryStatus || null
    } catch (error: any) {
      dispatch({ type: 'getDiscoveryStatusFailure', error: error?.type, description: error?.description })
      return null
    }
  }

const AddBasicsAction =
  (basics: DiscoveryBasicsInformation) =>
  async (dispatch: Dispatch<SetupDiscoveryActions>): Promise<AddDiscoveryBasicsResponse | null> => {
    try {
      dispatch({ type: 'addBasicsRequest', basics })
      const result: AddDiscoveryBasicsResponse = await setupDiscoveryService.addBasics(basics)
      dispatch({ type: 'addBasicsSuccess' })
      return result || null
    } catch (error: any) {
      HandleAddBasicsActionError(error, dispatch)
      return null
    }
  }

const GetBusinessCategoriesAction = () => async (dispatch: Dispatch<SetupDiscoveryActions>) => {
  try {
    dispatch({ type: 'getBusinessCategoriesRequest' })
    const categories: BusinessCategory[] = await pixieService.getBusinessCategories()
    dispatch({ type: 'getBusinessCategoriesSuccess', categories })
    return categories || null
  } catch (error: any) {
    dispatch({ type: 'getBusinessCategoriesFailure', error: error?.type, description: error?.description })
    return null
  }
}

const AddClassificationAction =
  (classification: DiscoveryClassification) =>
  async (dispatch: Dispatch<SetupDiscoveryActions>): Promise<BusinessCategoryResponse | null> => {
    try {
      dispatch({ type: 'addClassificationRequest', classification })
      const updatedClassification = {
        PrimaryCategoryId: `${classification.PrimaryCategoryId.toString()}`,
        SecondaryCategoryId: `${classification.SecondaryCategoryId.toString()}`,
        TertiaryCategoryId: `${classification.TertiaryCategoryId.toString()}`,
        Keywords: classification.Keywords,
      }
      const result: BusinessCategoryResponse = await setupDiscoveryService.addClassification(updatedClassification)
      dispatch({ type: 'addClassificationSuccess' })
      return result || null
    } catch (error: any) {
      dispatch({ type: 'addClassificationFailure', error: error?.type, description: error?.description })
      return null
    }
  }

const AddPhotosAction = (photosData: DiscoveryPhotosData) => async (dispatch: Dispatch<SetupDiscoveryActions>) => {
  try {
    dispatch({ type: 'addPhotosRequest', photosData })
    await setupDiscoveryService.addPhotos(photosData)
    dispatch({ type: 'addPhotosSuccess' })
  } catch (error: any) {
    dispatch({ type: 'addPhotosFailure', error: error?.type, description: error?.description })
  }
}

const AddCopyAction = (copyData: DiscoveryCopyData) => async (dispatch: Dispatch<SetupDiscoveryActions>) => {
  try {
    dispatch({ type: 'addCopyRequest', copyData })
    await setupDiscoveryService.addCopy(copyData)
    dispatch({ type: 'addCopySuccess' })
  } catch (error: any) {
    dispatch({ type: 'addCopyFailure', error: error?.type, description: error?.description })
  }
}

const AddBioPhotoAction = (bioPhoto: any) => async (dispatch: Dispatch<SetupDiscoveryActions>) => {
  try {
    dispatch({ type: 'addBioPhotoRequest', bioPhoto })
    await setupDiscoveryService.addBioPhoto(bioPhoto)
    dispatch({ type: 'addBioPhotoSuccess' })
  } catch (error: any) {
    dispatch({ type: 'addBioPhotoFailure', error: error?.type, description: error?.description })
  }
}

export { GetDiscoveryStatusAction, AddBasicsAction, GetBusinessCategoriesAction, AddClassificationAction, AddPhotosAction, AddCopyAction, AddBioPhotoAction }

/* error handling */

const HandleAddBasicsActionError = (error: any, dispatch: Dispatch<SetupDiscoveryActions>) => {
  if (IsApiTelephoneFormValidationError(error)) {
    const telephoneError = error as TelephoneValidationError
    dispatch({
      type: 'addBasicsFormValidationErrors',
      error: {
        path: 'telephone',
        message: telephoneError.modelState['basics.BusinessPhoneNumber'][0] ?? 'Please enter a valid phone number.',
      } as FormValidationApError,
    })
  } else if (IsApiFormValidationError(error)) {
    dispatch({
      type: 'addBasicsFormValidationErrors',
      error: { message: error.message, modelState: error.modelState } as FormValidationApError,
    })
  } else {
    dispatch({ type: 'addBasicsFailure', error: error?.errorCode, description: error?.error_description })
  }
}
