export type SignupStatusResponse = {
  haveCompletedBankDetails: boolean
  haveCompletedBusinessInformation: boolean
  haveCompletedLoyaltyPercent: boolean
  haveCompletedProofOfIdentity: boolean
  haveCompletedSignup: boolean
}

export type AddProofOfIdentityResponse = {
  result: string
}

export type AddBankDetailsResponse = {
  result: string
}

export type AddLoyaltyResponseError = {
  message: string
  modelState: string
}

export type AddDiscoveryBasicsResponse = {
  result: string
}

export type FormValidationApError = {
  path: string
  message: string
  modelState: string
}

export type BusinessCategory = {
  name: string
  shortName: string
  index: number
  id: string
}

export type BusinessCategoryResponse = {
  result: string
}

export type DiscoveryStatusResponse = {
  haveCompletedDiscovery: boolean
  haveCompletedBasics: boolean
  haveCompletedClassification: boolean
  haveCompletedPhotos: boolean
  haveCompletedCopy: boolean
}

export interface TransactionsForDateRange {
  utcStartDateInclusive?: Date
  utcEndDateExclusive?: Date
  transactions: TransactionElement[]
}

export const TransactionsForDateRangeInitialState: TransactionsForDateRange = {
  transactions: [],
}

export interface TransactionElement {
  timestamp: Date
  amount: number
  currencyCode: string
}

export type DiscoveryPhotosData = {
  nophotos: string
}

export type DiscoveryCopyData = {
  TagLine: string
  Description: string
  Quote: string
  QuoteAuthor: string
}

export type DashboardBusinessOverViewData = {
  hasMadeTransactions: boolean
  recentTransactions: TransactionElement[]
  takingsTodayPence: number
  loves: number
  loyaltyPercent: number
  openingHoursSunday?: string
  openingHoursMonday?: string
  openingHoursTuesday?: string
  openingHoursWednesday?: string
  openingHoursThursday?: string
  openingHoursFriday?: string
  openingHoursSaturday?: string
}

export const DashboardBusinessOverViewDataInitialState: DashboardBusinessOverViewData = {
  hasMadeTransactions: false,
  recentTransactions: [],
  loyaltyPercent: 0,
  takingsTodayPence: 0,
  loves: 0,
}

export type SetupBusinessInformationRequest = {
  ConfirmedIndependent: boolean
  BusinessName: string
  'TradingAddress.AddressLine1': string
  'TradingAddress.AddressLine2': string
  'TradingAddress.City': string
  'TradingAddress.County': string
  'TradingAddress.Postcode': string
  IsARegisteredBusiness: boolean
  IncorporationDate: string
  RegistrationNumber: string
  LegalName: string
  VatNumber: string
  'RegisteredAddress.AddressLine1': string
  'RegisteredAddress.AddressLine2': string
  'RegisteredAddress.City': string
  'RegisteredAddress.County': string
  'RegisteredAddress.Postcode': string
  BusinessLocationLatitude: string
  BusinessLocationLongitude: string
}

export type GetLoyaltyPercentResponse = {
  loyaltyPercent: number
}

export type OpeningHours = {
  openingHoursSunday?: string
  openingHoursMonday?: string
  openingHoursTuesday?: string
  openingHoursWednesday?: string
  openingHoursThursday?: string
  openingHoursFriday?: string
  openingHoursSaturday?: string
}

export interface TelephoneValidationError {
  message: string
  modelState: TelephoneValidationModelState
}

export interface TelephoneValidationModelState {
  'basics.BusinessPhoneNumber': string[]
}

export interface BusinessInformationTradingPostcodeValidationError {
  message: string
  modelState: BusinessInformationTradingPostcodeValidationModelState
}

export interface BusinessInformationTradingPostcodeValidationModelState {
  'businessInformation.TradingAddress.Postcode': string[]
}

export interface BusinessInformationRegisteredPostcodeValidationError {
  message: string
  modelState: BusinessInformationRegisteredPostcodeValidationModelState
}

export interface BusinessInformationRegisteredPostcodeValidationModelState {
  'businessInformation.RegisteredAddress.Postcode': string[]
}
