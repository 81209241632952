import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import Joi, { ObjectSchema, ValidationErrorItem } from 'joi'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { DangerInformationCard, InputMedium, LabelExtraSmall, LabelSmall } from '@pixieme/pixie-react-components'
import { AddLoyaltyPercentageAction } from '../../../../redux/reducers/SetupAccount/SetupAccountActionCreators'
import SetupHeaderCard from '../../../../appComponents/SetupHeader/SetupHeaderCard'
import BusyButton from '../../../../appComponents/buttons/AppButtons'
import { useAppSelector } from '../../../../redux/Hooks'
import ValidationCard from '../../../../appComponents/cards/ValidationCard'
import AppConfig from '../../../../config/appConfig'

const AccountDetailsLoyaltyPercent = React.forwardRef<HTMLDivElement, AccountDetailsLoyaltyPercentProps>(({ enabled, completed }, ref) => (
  <div ref={ref} className="bg-white w-full shadow-sm rounded-md" style={{ scrollMarginTop: AppConfig.navBarScrollMarginTop }}>
    <SetupHeaderCard completed={completed} enabled={enabled} labelLeft="Loyalty percentage" labelRight="Step 4 of 4" openState="closed">
      <div className="px-4 pb-6 ">
        <AccountDetailsLoyaltyPercentForm />
      </div>
    </SetupHeaderCard>
  </div>
))

export default AccountDetailsLoyaltyPercent

export const AccountDetailsLoyaltyPercentForm: FC<AccountDetailsLoyaltyPercentFormProps> = ({ previousLoyaltyPercent }) => {
  const [loyaltyPercent, setLoyaltyPercent] = useState<number | undefined>(previousLoyaltyPercent ?? 5)
  const [formValidationErrors, setFormValidationErrors] = useState<ValidationErrorItem[]>([])
  const {
    status: { addingLoyaltyPercentage },
  } = useAppSelector(({ setupAccountReducer: reducer }) => reducer)
  const appDispatch = useDispatch()

  const validateAndSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const validationErrors = ValidateSchema(ValidationSchema(), loyaltyPercent)

    if (validationErrors.length === 0) {
      setFormValidationErrors([])
      appDispatch(AddLoyaltyPercentageAction({ LoyaltyPercent: loyaltyPercent ?? 0 }))
    } else {
      setFormValidationErrors(validationErrors)
    }
  }

  return (
    <div>
      <form className="space-y-4">
        <LabelSmall
          variant="dark"
          label="Most customers choose to use Pixie because they receive loyalty points when they pay using Pixie across our community of businesses. Offering an attractive loyalty percentage is key to getting more customers through the door and can lead to increased spending in your business."
        />
        <LabelSmall
          variant="dark"
          label="We recommend offering 5% loyalty* or greater as a way of marketing and differentiating your business. This percentage will be deducted from your weekly settled transactions and will be allocated to the customer as loyalty points that they can only spend in any Pixie business."
        />
        <LabelSmall variant="dark" label="* minimum contribution is 2%" />
        <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Loyalty Percent" additionalCss="font-extrabold uppercase" />
          <InputMedium
            inputType="number"
            placeHolder="5"
            value={loyaltyPercent}
            min="2"
            max="100"
            onChange={(e) => setLoyaltyPercent(parseInt(e.target.value, 10))}
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.loyaltyPercent} />
        </div>
        {formValidationErrors.length > 0 && (
          <div className="mt-4">
            <DangerInformationCard
              label="There are some issues with the information you've entered above. Please fix these issues and try again."
              icon={faInfoCircle}
              iconSize="lg"
            />
          </div>
        )}
        <BusyButton
          isBusy={addingLoyaltyPercentage}
          label={previousLoyaltyPercent ? 'Save' : 'Save and continue'}
          size="small"
          roundedType="full"
          onClick={(e) => validateAndSubmit(e)}
          additionalCss={previousLoyaltyPercent ? 'px-8' : ''}
        />
      </form>
    </div>
  )
}

/* types */
type AccountDetailsLoyaltyPercentProps = {
  enabled: boolean
  completed: boolean
}

type AccountDetailsLoyaltyPercentFormProps = {
  previousLoyaltyPercent?: number
}

/* validation logic */
const validationFieldKeys = {
  loyaltyPercent: 'loyaltyPercent',
}

const ValidationSchema = (): ObjectSchema =>
  Joi.object().keys({
    loyaltyPercent: Joi.number().min(2).max(100).messages({
      'number.min': 'Loyalty Percentage must be between 2% and 100%',
      'number.max': 'Loyalty Percentage must be between 2% and 100%',
    }),
  })

const ValidateSchema = (schema: ObjectSchema, loyaltyPercent?: number): ValidationErrorItem[] => {
  const { error } = schema.validate({ loyaltyPercent }, { abortEarly: false })
  return error?.details ?? []
}
