import { Dispatch } from 'react'
import { authenticationService, userAccountService } from '../../../services/pixie'
import { UserAccount } from './UserAccountReducer'
import { CombinedActions } from '../../store'
import { LOCAL_STORAGE_CURRENT_USER_KEY } from '../Authentication/AuthReducer'

export type UserAccountActions =
  | { type: 'createAccountRequest'; email: string; password: string; optInToMarketing: boolean }
  | { type: 'createAccountSuccess' }
  | { type: 'createAccountFailure'; error: string; description: string }
  | { type: 'resetPasswordForm' }
  | { type: 'resetPasswordRequest'; email: string }
  | { type: 'resetPasswordSuccess' }
  | { type: 'resetPasswordFailure'; error: string; description: string }
  | { type: 'getAccountDetailsRequest' }
  | { type: 'getAccountDetailsSuccess'; userAccount: UserAccount }
  | { type: 'getAccountDetailsFailure'; error: string; description: string }
  | { type: 'resetStoreUserAccount' }

const CreateAccountAction = (email: string, password: string, optInToMarketing: boolean) => async (dispatch: Dispatch<CombinedActions>) => {
  try {
    dispatch({ type: 'createAccountRequest', email, password, optInToMarketing })
    const user = await userAccountService.createAccount(email, password, optInToMarketing)
    dispatch({ type: 'createAccountSuccess' })

    // Now login
    dispatch({ type: 'loginRequest', email, password })
    const loginUser = await authenticationService.login(email, password)
    // TODO: vulnerable to XSS attacks
    localStorage.setItem(LOCAL_STORAGE_CURRENT_USER_KEY, JSON.stringify(loginUser))

    const userAccount: UserAccount = await userAccountService.getAccountDetails()
    dispatch({ type: 'getAccountDetailsSuccess', userAccount })
    dispatch({ type: 'loginSuccess', user: loginUser })

    return user || null
  } catch (error: any) {
    dispatch({
      type: 'createAccountFailure',
      error: error?.errorType,
      description: error?.errorCode === 'email_address_already_registered' ? error?.message : 'An error has occurred trying to create your account !',
    })
    return null
  }
}

const ResetPasswordAction = (email: string) => async (dispatch: Dispatch<UserAccountActions>) => {
  try {
    dispatch({ type: 'resetPasswordRequest', email })
    const user = await userAccountService.resetPassword(email)
    dispatch({ type: 'resetPasswordSuccess' })
    return user || null
  } catch (error: any) {
    dispatch({
      type: 'resetPasswordFailure',
      error: error?.errorCode,
      description: 'An error has occurred trying to reset your password !',
    })
    return null
  }
}

const GetAccountDetailsAction =
  () =>
  async (dispatch: Dispatch<UserAccountActions>): Promise<UserAccount | null> => {
    try {
      dispatch({ type: 'getAccountDetailsRequest' })
      const userAccount = await userAccountService.getAccountDetails()

      dispatch({ type: 'getAccountDetailsSuccess', userAccount })
      return userAccount || null
    } catch (error: any) {
      dispatch({ type: 'getAccountDetailsFailure', error: error?.errorCode, description: error?.errorMessage })
      return null
    }
  }

export { CreateAccountAction, ResetPasswordAction, GetAccountDetailsAction }
