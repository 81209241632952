import { OpeningHourLineModel } from '@pixieme/pixie-react-components'

export type AppUser = {
  firstName: string
  lastName: string
  email: string
  token: string
  roles: string[]
}

export type BusinessInformation = {
  ConfirmedIndependent: boolean
  BusinessName: string
  TradingAddress: Address
  IsARegisteredBusiness: boolean
  IncorporationDate: string
  RegistrationNumber: string
  LegalName: string
  VatNumber: string
  RegisteredAddress: Address
  BusinessLocationLatitude: string
  BusinessLocationLongitude: string
}

export type Address = {
  AddressLine1: string
  AddressLine2: string
  City: string
  County: string
  Postcode: string
}

const AddressInitialState = {
  AddressLine1: '',
  AddressLine2: '',
  City: '',
  County: '',
  Postcode: '',
}

export const BusinessInformationInitialState = {
  ConfirmedIndependent: false,
  BusinessName: '',
  TradingAddress: AddressInitialState,
  IsARegisteredBusiness: false,
  IncorporationDate: '',
  RegistrationNumber: '',
  LegalName: '',
  VatNumber: '',
  RegisteredAddress: AddressInitialState,
  BusinessLocationLatitude: '',
  BusinessLocationLongitude: '',
}

export type ApiError = {
  code?: string
  description?: string
}

export type BusinessBankDetails = {
  BankName?: string
  SortCode?: string
  AccountNumber?: string
}

export type LoyaltyPercentDetails = {
  LoyaltyPercent: number
}

export type DiscoveryBasicsInformation = {
  businessPhoneNumber: string
  businessWebsite: string
  OpeningHours: OpeningHourLineModel[]
  openingHoursMonday: string
  openingHoursTuesday: string
  openingHoursWednesday: string
  openingHoursThursday: string
  openingHoursFriday: string
  openingHoursSaturday: string
  openingHoursSunday: string
  openingHoursNotes: string
}

export const DiscoveryBasicsInformationInitialState: DiscoveryBasicsInformation = {
  OpeningHours: [] as OpeningHourLineModel[],
  openingHoursFriday: '',
  openingHoursMonday: '',
  openingHoursNotes: '',
  openingHoursSaturday: '',
  openingHoursSunday: '',
  openingHoursThursday: '',
  openingHoursTuesday: '',
  openingHoursWednesday: '',
  businessPhoneNumber: '',
  businessWebsite: '',
}

export interface DiscoveryClassification {
  PrimaryCategoryId: string
  SecondaryCategoryId: string
  TertiaryCategoryId: string
  Keywords: string[]
}

export const DiscoveryClassificationInitialState: DiscoveryClassification = {
  PrimaryCategoryId: '',
  SecondaryCategoryId: '',
  TertiaryCategoryId: '',
  Keywords: [],
}

export const BusinessBankDetailsInitialState: BusinessBankDetails = {
  BankName: '',
  SortCode: '',
  AccountNumber: '',
}
