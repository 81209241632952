import React, { useEffect, useState } from 'react'
import { useImmerReducer } from 'use-immer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Label2XLarge, Label4XLarge, LabelMedium } from '@pixieme/pixie-react-components'
import { useDispatch } from 'react-redux'
import { DiscoverySetupDispatchContext, DiscoverySetupStateContext } from '../setup/DiscoverySetupPage'
import { DiscoveryDetailsBasicsForm } from '../setup/DiscoverySetupPage/components/DiscoveryDetailsBasics'
import { DiscoverySetupInitialState, DiscoverySetupReducer } from '../setup/DiscoverySetupPage/context/DiscoverySetupReducer'
import { setupDiscoveryService } from '../../services/pixie'
import Mappers from '../../utils/ApiMappers'
import { useAppSelector } from '../../redux/Hooks'

const DiscoverySettingsPage = () => {
  const [state, dispatch] = useImmerReducer(DiscoverySetupReducer, DiscoverySetupInitialState)
  const [loading, setLoading] = useState<boolean>(true)
  const {
    status: { savedBasics },
  } = useAppSelector(({ setupDiscoveryReducer: reducer }) => reducer)
  const appDispatch = useDispatch()

  useEffect(() => {
    appDispatch({ type: 'resetBasics' })
    setupDiscoveryService
      .getBasics()
      .then((response) => {
        const openingHours = new Mappers().MapOpeningHoursResponseToOpeningHours(response)

        dispatch({
          type: 'discoveryBasicsInformationChanged',
          discoveryBasicsInformation: { ...response, OpeningHours: openingHours },
        })
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <DiscoverySetupDispatchContext.Provider value={dispatch}>
      <DiscoverySetupStateContext.Provider value={state}>
        <div className="md:max-w-5xl mx-auto px-2">
          <div className="bg-white shadow-sm rounded-md p-4 sm:p-8 mb-8">
            <Label4XLarge variant="dark" label="Business Profile" additionalCss="font-extrabold tracking-tight hidden sm:flex" />
            <Label2XLarge variant="dark" label="Business Profile" additionalCss="font-extrabold tracking-tight sm:hidden" />
            <div className="pt-4 sm:pt-8">
              {/* eslint-disable-next-line no-nested-ternary */}
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} size="lg" className="text-gray-400 my-2" spin />
              ) : savedBasics ? (
                <BusinessInformationSaved />
              ) : (
                <DiscoveryDetailsBasicsForm editMode="Edit" />
              )}
            </div>
          </div>
        </div>
      </DiscoverySetupStateContext.Provider>
    </DiscoverySetupDispatchContext.Provider>
  )
}

export default DiscoverySettingsPage

const BusinessInformationSaved = () => (
  <div>
    <div className="sm:flex py-2 bg-white rounded-md flex space-x-4 items-center">
      <FontAwesomeIcon icon={faCheckCircle} size="2x" className="text-success" />
      <LabelMedium variant="dark" label="Your changes have been saved successfully" />
    </div>
  </div>
)
