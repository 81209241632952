import { Dispatch } from 'react'
import { AppActions } from '../App/AppActionCreators'
import { UserAccountActions } from '../UserAccount/UserAccountActionCreators'
import { setupAccountService } from '../../../services/pixie'
import { BusinessBankDetails, BusinessInformation, LoyaltyPercentDetails } from '../../../models/models'
import { getLatLongForPostcode } from '../../../utils/postcodeHelpers'
import {
  AddBankDetailsResponse,
  AddProofOfIdentityResponse,
  BusinessInformationRegisteredPostcodeValidationError,
  BusinessInformationTradingPostcodeValidationError,
  FormValidationApError,
  SignupStatusResponse,
} from '../../../services/pixie/Models/ApiModels'
import Mappers from '../../../utils/ApiMappers'
import { IsBusinessInformationRegisteredPostcodeValidationError, IsBusinessInformationTradingPostcodeValidationError } from '../../../helpers/ApiHelpers'

export type SetupAccountActions =
  | { type: 'getSignupStatusRequest' }
  | { type: 'getSignupStatusSuccess'; signupStatus: SignupStatusResponse }
  | { type: 'getSignupStatusFailure'; error: string; description: string }
  | { type: 'resetBusinessInformation' }
  | { type: 'addBusinessInformationRequest'; business: BusinessInformation }
  | { type: 'addBusinessInformationSuccess' }
  | { type: 'addBusinessInformationFailure'; error: string; description: string }
  | { type: 'addBusinessInformationFormValidationErrors'; error: FormValidationApError }
  | { type: 'addProofOfIdentityRequest'; file: File }
  | { type: 'addProofOfIdentitySuccess' }
  | { type: 'addProofOfIdentityFailure'; error: FormValidationApError }
  | { type: 'addBankDetailsRequest'; businessBankDetails: BusinessBankDetails }
  | { type: 'addBankDetailsSuccess' }
  | { type: 'addBankDetailsFailure'; error: string; description: string }
  | { type: 'resetLoyaltyPercentage' }
  | { type: 'addLoyaltyPercentageRequest'; loyaltyPercentageDetails: LoyaltyPercentDetails }
  | { type: 'addLoyaltyPercentageSuccess' }
  | { type: 'addLoyaltyPercentageFailure'; error: string; description: string }
  | { type: 'resetStoreSetupAccount' }

const GetSignupStatusAction = () => async (dispatch: Dispatch<SetupAccountActions>) => {
  try {
    dispatch({ type: 'getSignupStatusRequest' })
    const signupStatus: SignupStatusResponse = await setupAccountService.getSignupStatus()
    dispatch({ type: 'getSignupStatusSuccess', signupStatus })
    return signupStatus || null
  } catch (error: any) {
    dispatch({ type: 'getSignupStatusFailure', error: error?.errorType, description: error?.message })
    return null
  }
}

const AddBusinessInformationAction = (business: BusinessInformation) => async (dispatch: Dispatch<UserAccountActions | AppActions | SetupAccountActions>) => {
  try {
    const businessToAdd = { ...business }

    if (businessToAdd.TradingAddress.Postcode) {
      const location = await getLatLongForPostcode(businessToAdd.TradingAddress.Postcode)
      businessToAdd.BusinessLocationLatitude = location?.lat
      businessToAdd.BusinessLocationLongitude = location?.lon
    }

    dispatch({ type: 'addBusinessInformationRequest', business: businessToAdd })
    await setupAccountService.addBusinessInformation(new Mappers().MapToSetupBusinessInformationRequest(businessToAdd))
    dispatch({ type: 'addBusinessInformationSuccess' })

    return businessToAdd || null
  } catch (error: any) {
    HandleBusinessInformationAction(error, dispatch)
    return null
  }
}

const AddProofOfIdentityAction = (fileUpload: File) => async (dispatch: Dispatch<SetupAccountActions>) => {
  try {
    dispatch({ type: 'addProofOfIdentityRequest', file: fileUpload })
    const result: AddProofOfIdentityResponse = await setupAccountService.addProofOfIdentity(fileUpload)
    dispatch({ type: 'addProofOfIdentitySuccess' })

    return result || null
  } catch (error: any) {
    dispatch({
      type: 'addProofOfIdentityFailure',
      error: {
        path: 'uploadImage',
        message: 'An error occurred uploading your proof of identity',
      } as FormValidationApError,
    })
    return null
  }
}

const AddBankDetailsAction = (businessBankDetails: BusinessBankDetails) => async (dispatch: Dispatch<SetupAccountActions>) => {
  try {
    dispatch({ type: 'addBankDetailsRequest', businessBankDetails })
    const result: AddBankDetailsResponse = await setupAccountService.addBankDetails(businessBankDetails)
    dispatch({ type: 'addBankDetailsSuccess' })
    return result || null
  } catch (error: any) {
    dispatch({ type: 'addBankDetailsFailure', error: error?.errorType, description: error?.message })
    return null
  }
}

const AddLoyaltyPercentageAction = (loyaltyPercentageDetails: LoyaltyPercentDetails) => async (dispatch: Dispatch<SetupAccountActions>) => {
  try {
    dispatch({ type: 'addLoyaltyPercentageRequest', loyaltyPercentageDetails })
    const result = await setupAccountService.addLoyaltyPercentage(loyaltyPercentageDetails)
    dispatch({ type: 'addLoyaltyPercentageSuccess' })
    return result || null
  } catch (error: any) {
    dispatch({ type: 'addLoyaltyPercentageFailure', error: error?.message, description: error?.modelState })
    return null
  }
}

export { AddBusinessInformationAction, GetSignupStatusAction, AddProofOfIdentityAction, AddBankDetailsAction, AddLoyaltyPercentageAction }

/* error handling */

const HandleBusinessInformationAction = (error: any, dispatch: Dispatch<SetupAccountActions>) => {
  if (IsBusinessInformationTradingPostcodeValidationError(error)) {
    const postcodeError = error as BusinessInformationTradingPostcodeValidationError
    dispatch({
      type: 'addBusinessInformationFormValidationErrors',
      error: {
        path: 'tradingPostcode',
        message: postcodeError.modelState['businessInformation.TradingAddress.Postcode'][0] ?? 'Please enter a valid postcode.',
      } as FormValidationApError,
    })
  } else if (IsBusinessInformationRegisteredPostcodeValidationError(error)) {
    const postcodeError = error as BusinessInformationRegisteredPostcodeValidationError
    dispatch({
      type: 'addBusinessInformationFormValidationErrors',
      error: {
        path: 'registeredPostcode',
        message: postcodeError.modelState['businessInformation.RegisteredAddress.Postcode'][0] ?? 'Please enter a valid postcode.',
      } as FormValidationApError,
    })
  } else {
    dispatch({ type: 'addBusinessInformationFailure', error: error?.errorCode, description: error?.errorMessage })
  }
}
