import { Disclosure } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { navigationApproved, navigationNonApproved, SideBarMenuProps, SideMenuItemType } from './NavigationRoutes'
import { useAppSelector } from '../../../redux/Hooks'
import { businessApprovedWithoutTransactions, businessApprovedWithTransactions } from '../../../helpers/BusinessStatusHelper'
import Logger from '../../../utils/logger'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const AppSidebar = () => {
  const { userAccount } = useAppSelector(({ userAccountReducer: reducer }) => reducer)
  const navigation: SideBarMenuProps[] =
    businessApprovedWithTransactions(userAccount) || businessApprovedWithoutTransactions(userAccount) ? navigationNonApproved : navigationApproved

  return (
    <>
      <div className="z-40 hidden sm:flex w-64 border-r border-gray-200 pb-4 bg-white pt-20 fixed inset-y-0 overflow-x-hidden overflow-y-auto sm:block">
        <SideMenuDesktop navigation={navigation} />
      </div>
    </>
  )
}

const AppMobileMenu = () => {
  const { userAccount } = useAppSelector(({ userAccountReducer: reducer }) => reducer)
  const navigation: SideBarMenuProps[] =
    businessApprovedWithTransactions(userAccount) || businessApprovedWithoutTransactions(userAccount) ? navigationApproved : navigationNonApproved

  return (
    <>
      <div className="z-40 absolute sm:hidden z-40 w-full border-r border-gray-200 pb-4 bg-white border-b shadow-md">
        <SideMenuMobile navigation={navigation} />
      </div>
    </>
  )
}

const SideMenuDesktop: FC<SideMenuProps> = ({ navigation }) => (
  <nav className="overflow-hidden px-2 space-y-1 w-full bg-white" aria-label="Sidebar">
    <MenuNavigation navigation={navigation} keepSubMenusOpen />
  </nav>
)

const SideMenuMobile: FC<SideMenuProps> = ({ navigation }) => (
  <Disclosure defaultOpen as="nav" className="z-10 bg-white w-full">
    <Disclosure.Panel className="mt-16 ml-4">
      <>
        <MenuNavigation navigation={navigation} closeOnNavigation keepSubMenusOpen />
      </>
    </Disclosure.Panel>
  </Disclosure>
)

const MenuNavigation: FC<SideMenuProps> = ({ navigation, closeOnNavigation = false, keepSubMenusOpen = false }) => {
  const { selectedSideMenuitemType } = useAppSelector(({ appReducer: reducer }) => reducer)
  const [currentItem, setCurrentItem] = useState<SideMenuItemType>('dashboard')
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedSideMenuitemType !== null) {
      setCurrentItem(selectedSideMenuitemType)
    }
  }, [selectedSideMenuitemType])

  Logger.info(`MenuNavigation ${currentItem} ${selectedSideMenuitemType}`)

  const navigateToUrl = (e: React.MouseEvent<HTMLButtonElement>, menuItem: SideBarMenuProps) => {
    e.preventDefault()
    if (menuItem.href) {
      menuItem.current = true
      setCurrentItem(menuItem.menuItemType)
      if (closeOnNavigation) {
        dispatch({ type: 'closeMobileMenu' })
      }
      if (menuItem.autoCloseMenu) {
        dispatch({ type: 'closeSideMenu' })
      }
      history.push(menuItem.href)
    }
  }

  return (
    <>
      {navigation.map((item) =>
        !item.children ? (
          <div key={item.name}>
            <button
              type="button"
              onClick={(e) => navigateToUrl(e, item)}
              className={classNames(
                item.menuItemType === currentItem
                  ? 'bg-primary text-white hover:bg-primary hover:text-white'
                  : 'bg-white text-gray-600 hover:bg-primary hover:text-white',
                'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
              )}>
              {item.icon && (
                <FontAwesomeIcon
                  icon={item.icon}
                  size="lg"
                  className={classNames(item.menuItemType === currentItem ? 'text-white' : 'text-gray-400', 'group-hover:text-white mr-3 flex-shrink-0')}
                />
              )}
              {item.name}
            </button>
          </div>
        ) : (
          <Disclosure defaultOpen={keepSubMenusOpen} as="div" key={item.name} className="space-y-1">
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={classNames(
                    item.menuItemType === currentItem ? 'bg-primary text-white' : 'bg-white text-gray-600 hover:bg-primary hover:text-white',
                    'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none'
                  )}>
                  {item.icon && (
                    <FontAwesomeIcon
                      icon={item.icon}
                      size="lg"
                      className={classNames(item.menuItemType === currentItem ? 'text-white' : 'text-gray-400', 'group-hover:text-white mr-3 flex-shrink-0')}
                    />
                  )}
                  <span className="flex-1">{item.name}</span>
                  <svg
                    className={classNames(
                      open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                      'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-white transition-colors ease-in-out duration-150'
                    )}
                    viewBox="0 0 20 20"
                    aria-hidden="true">
                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                  </svg>
                </Disclosure.Button>
                <Disclosure.Panel className="space-y-1">
                  {item?.children?.map((subItem) => (
                    <button
                      type="button"
                      key={subItem.name}
                      onClick={(e) => navigateToUrl(e, subItem)}
                      className={classNames(
                        subItem.menuItemType === currentItem ? 'bg-primary text-white' : 'bg-white text-gray-600 hover:bg-primary hover:text-white',
                        'group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium rounded-md'
                      )}>
                      {subItem.name}
                    </button>
                  ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        )
      )}
    </>
  )
}

export { AppSidebar, AppMobileMenu }

/* types */
type SideMenuProps = {
  navigation: SideBarMenuProps[]
  closeOnNavigation?: boolean
  keepSubMenusOpen?: boolean
}
